<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo align-items-center">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">Ohlive</h2>
      </b-link>
      <!-- /Brand logo-->

      <b-col lg="7" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img class="registerImage" fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>

      <!-- Left Text-->

      <b-col lg="5" class="d-flex align-items-center auth-bg px-0 p-lg-2">
        <!-- <div class="fl_name"> -->

        <b-col sm="8" md="6" lg="12" class="px-xl-2 pt-5 pt-lg-0 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Lets get Acquainted!
          </b-card-title>

          <b-card-text class="mb-2">
            Once you get to know us, you cant stay away from us!
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <div class="fl_name">
                <!-- firstname -->
                <b-form-group label="First name" label-for="register-firstname">
                  <validation-provider
                    #default="{ errors }"
                    name="Firstname"
                    rules="required"
                  >
                    <b-form-input
                      id="register-firstname"
                      v-model="form.firstname"
                      name="register-firstname"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- lastname -->

                <b-form-group label="Last name" label-for="register-lastname">
                  <validation-provider
                    #default="{ errors }"
                    name="Lastname"
                    rules="required"
                  >
                    <b-form-input
                      id="register-lastname"
                      v-model="form.lastname"
                      name="register-lastname"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div class="fl_name">
                <!-- Phone number -->
                <b-form-group label="Phone number" label-for="register-mobile">
                  <validation-provider
                    #default="{ errors }"
                    name="Phone number"
                    rules="required"
                  >
                    <b-form-input
                      id="register-mobile"
                      v-model="form.mobile"
                      type="number"
                      name="register-mobile"
                      :state="errors.length > 0 ? false : null"
                      placeholder="+91XXXXXXXXXX"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- email -->
                <b-form-group label="Email" label-for="register-email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="register-email"
                      v-model="form.email"
                      name="register-email"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div class="fl_name">
                <!-- password -->
                <b-form-group label-for="register-password" label="Password">
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="register-password"
                        v-model="form.password"
                        class="form-control-merge rp_padding"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false : null"
                        name="register-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- confirm password -->
                <b-form-group
                  label-for="register-password2"
                  label="Confirm Password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="register-password2"
                        v-model="form.confirm_password"
                        class="form-control-merge rp_padding"
                        :type="passwordFieldTypeRepeat"
                        :state="errors.length > 0 ? false : null"
                        name="register-password2"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIconRepeat"
                          class="cursor-pointer"
                          @click="togglePasswordVisibilityRepeat"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="fl_name">
                <b-button
                  variant="primary"
                  block
                  type="submit"
                  @click.prevent="dummyRegister"
                >
                  Sign up
                </b-button>
              </div>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AuthServices from "@/apiServices/AuthServices";
import { TokenService } from "@/apiServices/StorageService";

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      form: {
        status: "",
        firstname: "",
        lastname: "",
        mobile: "",
        email: "",
        password: "",
        confirm_password: "",
      },
      passwordFieldType: "password",
      passwordFieldTypeRepeat: "password",
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconRepeat() {
      return this.passwordFieldTypeRepeat === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    dummyRegister() {
      this.$router.push({ name: "home" });
    },
    async Register() {
      this.$refs.registerForm.validate().then(async (success) => {
        if (success) {
          if (this.form.password !== this.form.confirm_password) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Password does not match",
                icon: "EditIcon",
                variant: "failure",
              },
            });
            return;
          }

          try {
            let response = await AuthServices.Register(this.form);

            if (response && response.data.status) {
              TokenService.saveToken(response.data.data.token);

              this.$store.commit(
                "user/SET_USER_DETAILS",
                response.data.data.user
              );

              this.$store.commit("user/SET_USER_AUTHENTICATED", true);

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || "Signed Up",
                  icon: "EditIcon",
                  variant: "success",
                },
              });

              this.$router.push({ name: "home" });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || "Validation Failed",
                  icon: "EditIcon",
                  variant: "failure",
                },
              });
            }
          } catch (error) {
            console.log("Register ", error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Server Error",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        }
      });
    },
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    togglePasswordVisibilityRepeat() {
      this.passwordFieldTypeRepeat =
        this.passwordFieldTypeRepeat === "password" ? "text" : "password";
    },
  },
  beforeMount() {}
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@media (min-width: 768px) {
  .fl_name {
    display: flex !important;
    justify-content: space-between !important;
  }
}

@import "@core/scss/vue/pages/page-auth.scss";
</style>
